<template>
  <v-container class="pa-0" fluid style="height: 100%;">
    <div class="d-flex flex-md-row flex-column" style="height: 100%">

      <div class="column order-1 order-md-0 left-column d-flex flex-column pa-10 bg-surface">

        <div class="mb-5">
          <v-img id="logo" width="250px" src="/images/logo/logo_light.png" />
        </div>

        <div class="w-75 text-primary">
          Transforme a gestão do seu negócio hoje mesmo com Apex Comércio!!
        </div>

        <div class="w-100 flex-grow-1 d-flex justify-center align-center">
          <v-img src="/images/app/image3.png" max-width="600px" />
        </div>
      </div>

      <div class="column order-0 order-md-1 d-flex flex-column justify-center pl-md-12 px-4 py-0">
        <slot />
      </div>
    </div>
  </v-container>
</template>

<style scoped>
/* desktop */
@media (min-width: 960px) {
  .column {
    width: 50%;
    height: 100%;
  }

  .form {
    padding-right: 156px;
  }

}

/* mobile*/
@media (max-width: 960px) {
  .column {
    width: 100%;
  }

  .left-column {
    display: none !important;
  }

}
</style>